import { template as template_000001a3f930420e991c3262d8650eeb } from "@ember/template-compiler";
const FKText = template_000001a3f930420e991c3262d8650eeb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
