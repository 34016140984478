import { template as template_5b90e87d5b814cdebbf1ad0e764b371e } from "@ember/template-compiler";
const FKControlMenuContainer = template_5b90e87d5b814cdebbf1ad0e764b371e(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
