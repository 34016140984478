import { template as template_140f62d24d3b403c9e864ed086ae2b87 } from "@ember/template-compiler";
const WelcomeHeader = template_140f62d24d3b403c9e864ed086ae2b87(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
