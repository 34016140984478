import { template as template_b2b84dbc7ae34d2e9ed02c1959d70bd9 } from "@ember/template-compiler";
const FKLabel = template_b2b84dbc7ae34d2e9ed02c1959d70bd9(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
