import { template as template_a6eecac70f064b379d8c23c2f92b9338 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_a6eecac70f064b379d8c23c2f92b9338(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
